import React from 'react'
import { Box } from '@material-ui/core'
import SEO from "../components/seo"
import Layout from "../components/Layout/"
import PartnerWithUsLayout from '../components/Layout/PartnerWithUsLayout'

const Refer = () => {
    return (
        <>
            <SEO title="Refer" />
            <Layout withFooter withHeader>
                <Box>
                    <PartnerWithUsLayout />
                </Box>
            </Layout>
        </>
    )
}

export default Refer
